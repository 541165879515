import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import 'firebase/storage'
import * as firebase from 'firebase';
import { ComponentsService } from '../components/components.service';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { environment } from '../../../environments/environment';
import CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import { NgxXmlToJsonService } from 'ngx-xml-to-json';

declare var google;

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    // Obsevables filters
    public latitudeOrigin = new BehaviorSubject<any>('');
    originlatitude = this.latitudeOrigin.asObservable();
    public longitudeOrigin = new BehaviorSubject<any>('');
    originlongititude = this.longitudeOrigin.asObservable();

    // complete address details
    public ADDRESS_DETAIL = new BehaviorSubject<any>('');
    COMPLETE_ADDRESS = this.ADDRESS_DETAIL.asObservable();

    countryCode: any = null;

    constructor(
        public db: AngularFirestore,
        public components: ComponentsService,
        public router: Router,
        public loadingCtrl: LoadingController,
        public _ZONE: NgZone,
        public geolocation: Geolocation,
        private http: HttpClient,
        // private ngxXmlToJsonService: NgxXmlToJsonService
    ) {

    }

    ///////////////////////////////
    // GENERIC CRUD API REQUESTS
    ///////////////////////////////
    getAllDocuments(collection: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collection)
                .get()
                .toPromise()
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var obj = JSON.parse(JSON.stringify(doc.data()));
                        obj.$key = doc.id
                        arr.push(obj);
                    });

                    if (arr.length > 0) {
                        resolve(arr);
                    } else {
                        resolve(null);
                    }


                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getDocument(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    resolve(doc);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    deleteDocument(collectionName: string, docID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .delete()
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    addDocument(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collectionName).add(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .update(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    setDocument(collectionName: string, docID: string, dataObj: any) {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .set(dataObj)
                .then((obj: any) => {
                    resolve(obj)
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    getRef(collection) {
        return this.db.collection(collection);
    }

    // 
    // 
    // ESTA PARTE DEL API CONTIENE TODO EL BACK DE LOS MAPAS
    // 
    // 

    async loading(message: string) {
        const loader = await this.loadingCtrl.create({
            message
        });
        return loader;
    }

    block: any;
    street: any;
    building: any;
    pickup: string;
    lat: any;
    lng: any;
    markers = [];

    changeFilterPicup(lat: any, lng: any,) {
        this.latitudeOrigin.next(lat);
        this.longitudeOrigin.next(lng);
    }

    async getCurrentLoaction() {
        const loader = await this.loading('Obteniendo tu locación...');

        loader.present();

        this.geolocation.getCurrentPosition().then(data => {
            this._ZONE.run(() => {
                const latLng = new google.maps.LatLng(data.coords.latitude, data.coords.longitude);
                const mapOptions = {
                    center: latLng,
                    zoom: 15,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                this.lat = data.coords.latitude;
                this.lng = data.coords.longitude;
                this.changeFilterPicup(this.lat, this.lng);
                this.getGeoLocation(data.coords.latitude, data.coords.longitude);
                loader.dismiss();
            });
            loader.dismiss();
        }).catch((error) => {
            loader.dismiss();
        }).finally(() => {
            loader.dismiss();
        })

        loader.dismiss();
        const watch = this.geolocation.watchPosition();
        watch.subscribe((data: any) => {
            console.log('moviendo');
            loader.dismiss();
        });
    }

    async getGeoLocation(lat: number, lng: number) {
        if (navigator.geolocation) {
            const geocoder = await new google.maps.Geocoder();
            const latlng = await new google.maps.LatLng(lat, lng);
            const request = { latLng: latlng };

            await geocoder.geocode(request, (results: any[], status: any) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    const result = results[0];
                    const rsltAdrComponent = result.address_components;

                    if (result != null) {
                        if (rsltAdrComponent[0] != null) {

                            this.building = rsltAdrComponent[1].short_name;
                            this.street = rsltAdrComponent[2].short_name;
                            this.pickup = this.street + ' ' + this.building;

                            this.completeAddr(this.pickup);
                        }
                    } else {
                        alert('No address available!');
                    }
                }
            });
        }
    }

    completeAddr(CURRENT_ADDRESS: any) {
        this.ADDRESS_DETAIL.next(CURRENT_ADDRESS);
    }

    async markerDragEnd(lat, lng) {

        const geocoder = await new google.maps.Geocoder();
        const latlng = await new google.maps.LatLng(lat, lng);
        const request = { latLng: latlng };

        await geocoder.geocode(request, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                const result = results[0];
                const rsltAdrComponent = result.address_components;
                if (result !== null) {
                    if (rsltAdrComponent[0] !== null) {
                        this.block = rsltAdrComponent[0].long_name;
                        this.street = rsltAdrComponent[2].short_name;
                        this.building = rsltAdrComponent[1].short_name;
                    }
                    this._ZONE.run(() => {
                        this.lat = lat;
                        this.lng = lng;
                        // this.changeFilterPicup(this.lat, this.lng);
                        this.pickup = this.block + ' ' + this.street + ' ' + this.building;
                        this.completeAddr(this.pickup);
                    });

                } else {
                    alert('No address available!');
                }

            }
        });

    }




    uploadToCloudinary(file) {
        return new Promise((resolve, reject) => {

            const timestamp = Math.round((new Date).getTime() / 1000);

            let public_id = this.makeid(20);

            const formData = new FormData();
            formData.append("file", file);

            formData.append("api_key", environment.cloudinary.api_key);
            formData.append("public_id", public_id);
            formData.append("return_delete_token", "1");
            formData.append("timestamp", String(timestamp));
            var signature = CryptoJS.SHA1(`public_id=${public_id}&return_delete_token=1&timestamp=${String(timestamp)}${environment.cloudinary.api_secret}`);
            formData.append("signature", String(signature));

            this.http.post(environment.cloudinary.upload.url, formData)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });

        })
    }

    /**
     * It takes an image object as a parameter, creates a formData object, appends the required parameters
     * to the formData object, creates a signature, appends the signature to the formData object, and then
     * sends the formData object to the Cloudinary API
     * @param image - The image object that you want to delete.
     * @returns A promise.
     */
    deleteToCloudinary(image) {
        return new Promise((resolve, reject) => {
            const timestamp = Math.round((new Date).getTime() / 1000);

            const formData = new FormData();
            console.log(environment.cloudinary.api_key);
            console.log(image);


            formData.append("api_key", environment.cloudinary.api_key);
            formData.append("public_id", image.public_id);
            formData.append("timestamp", String(timestamp));
            var signature = CryptoJS.SHA1(`public_id=${image.public_id}&timestamp=${String(timestamp)}${environment.cloudinary.api_secret}`);
            formData.append("signature", String(signature));
            this.http.post(environment.cloudinary.delete.url, formData)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });

        })
    }

    /**
     * It generates a random string of a given length
     * @param length - The length of the random string.
     * @returns A random string of characters.
     */
    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    makePayment(payment_method) {
        return new Promise((resolve, reject) => {


            let cardNumber = payment_method.cardNumber.replace(/\s/g, '');
            this.http.get(`${environment.tokenization_api}?card=${cardNumber}&cvv=${payment_method.cardCvv}`)
                .subscribe(response => {

                    let card = payment_method;
                    card.cardCvv = response['cvv'];
                    card.cardtoken = response['card'];
                    card.cardExpDate = card.year + card.month;
                    card.cardName = `${card.name} ${card.lastname}`;

                    let batch = this.db.firestore.batch();

                    let payment_key = this.db.createId();

                    let postData = {
                        usuarioCliente: 'alfred-october',
                        monto: 20,
                        numeroTarjeta: card.cardtoken,
                        cvv2: card.cardCvv,
                        fechaExpiracion: card.cardExpDate,
                        nombreTarjetahabiente: card.cardName,
                        usuarioCorreo: card.email
                    }

                    this.http.post(`${environment.api}/payments`, postData).subscribe(response_server => {

                        let response: any = this.xmlStringToJson(response_server['response']['body']);

                        if (response['soap:Envelope']['soap:Body']['soap:Fault']) {
                            let error = response['soap:Envelope']['soap:Body']['soap:Fault']
                            reject(error);
                        } else {
                            response = response['soap:Envelope']['soap:Body']['ns1:realizarPagoResponse']['return']['#text'];
                            response = JSON.parse(response);
                            if (response.mensajeRetorno == 'TRANSACCION EXITOSA') {

                                batch.set(this.db.firestore.collection(`payments_october`).doc(payment_key), {
                                    amount: 20,
                                    account_key: 'alfred-october',
                                    creation_date: new Date(),
                                    authorization: response.numeroAutorizacion,
                                    payway_number: response.numeroPayWay,
                                    reference: response.numeroReferencia,
                                    name: card.cardName,
                                    card: payment_method.cardNumber.slice(-4),
                                    fechaExpiracion: card.cardExpDate,
                                });

                                batch.commit().then(data => {
                                    resolve(response);
                                }, err => {
                                    reject(response);
                                });
                            } else {
                                reject(response.mensajeRetorno)
                            }
                        }

                    })


                }, err => {
                    console.log(err);
                })


        })
    }


    xmlStringToJson(xml: string) {
        // Convert the XML string to an XML Document.
        const oParser = new DOMParser();
        const oDOM = oParser.parseFromString(xml, "application/xml");
        // Convert the XML Document to a JSON Object.
        return this.xmlToJson(oDOM);
    }

    /**
     * REF: https://davidwalsh.name/convert-xml-json
     */
    xmlToJson(xml) {
        // Create the return object
        var obj = {};

        if (xml.nodeType == 1) { // element
            // do attributes
            if (xml.attributes.length > 0) {
                obj["@attributes"] = {};
                for (var j = 0; j < xml.attributes.length; j++) {
                    var attribute = xml.attributes.item(j);
                    obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType == 3) { // text
            obj = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                var nodeName = item.nodeName;
                if (typeof (obj[nodeName]) == "undefined") {
                    obj[nodeName] = this.xmlToJson(item);
                } else {
                    if (typeof (obj[nodeName].push) == "undefined") {
                        var old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    }


    sendNotification(account_key, params) {
        return new Promise((resolve, reject) => {
            this.getAllDocuments(`accounts/${account_key}/push_keys`,).then(keys => {
                let push_keys = [];
                if (keys) {
                    keys.forEach(element => {
                        push_keys.push(element.push_key);
                    });

                    const post_data = {
                        'app_id': 'a14b9856-decf-4ca3-8e41-a1f9b9b0e3b3',
                        'headings': {
                            'en': params.title,
                            'es': params.title,
                        },
                        'contents': {
                            'en': params.message,
                            'es': params.message,
                        },
                        "include_player_ids": push_keys,
                    }

                    this.http.post('https://onesignal.com/api/v1/notifications', post_data)
                        .subscribe(response => {
                            resolve(response);
                        }, error => {
                            reject(error);
                        });

                } else {
                    resolve(true);
                }
            }, err => {
                reject(err);
            });

        });
    }

    sendDriverNotification(account_key, params) {

        return new Promise((resolve, reject) => {

            this.getAllDocuments(`drivers/${account_key}/push_keys`,).then(keys => {
                let push_keys = [];
                console.log(keys);

                if (keys) {
                    keys.forEach(element => {
                        push_keys.push(element.push_key);
                    });

                    const post_data = {
                        'app_id': '837b9e8e-1d45-4d58-91ce-8dc4a69005c3',
                        'headings': {
                            'en': params.title,
                            'es': params.title,
                        },
                        'contents': {
                            'en': params.message,
                            'es': params.message,
                        },
                        "include_player_ids": push_keys,
                    }

                    this.http.post('https://onesignal.com/api/v1/notifications', post_data)
                        .subscribe(response => {
                            resolve(response);
                        }, error => {
                            reject(error);
                        });

                } else {
                    resolve(true);
                }
            }, err => {
                reject(err);
            });

        });
    }

    sendAllDriversNotification(params) {

        return new Promise((resolve, reject) => {


            this.getAllDocuments(`drivers`).then(all_drivers => {
                all_drivers.forEach(element => {

                    console.log(element);

                    this.getAllDocuments(`drivers/${element.$key}/push_keys`,).then(keys => {
                        let push_keys = [];
                        console.log(keys);

                        if (keys) {
                            keys.forEach(element => {
                                push_keys.push(element.push_key);
                            });

                            const post_data = {
                                'app_id': '837b9e8e-1d45-4d58-91ce-8dc4a69005c3',
                                'headings': {
                                    'en': params.title,
                                    'es': params.title,
                                },
                                'contents': {
                                    'en': params.message,
                                    'es': params.message,
                                },
                                "include_player_ids": push_keys,
                            }

                            this.http.post('https://onesignal.com/api/v1/notifications', post_data)
                                .subscribe(response => {
                                    resolve(response);
                                }, error => {
                                    reject(error);
                                });

                        } else {
                            resolve(true);
                        }
                    }, err => {
                        reject(err);
                    });
                });
            })



        });
    }

}
