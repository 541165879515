import { Component } from '@angular/core';
import { Plugins } from "@capacitor/core";
import { ModalController, Platform } from '@ionic/angular';
const { IOSAppTracking } = Plugins
import { AppTrackingTransparency } from "capacitor-ios-app-tracking";
import { ComponentsService } from './services/components/components.service';
import { ApiService } from './services/api/api.service';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})

export class AppComponent {
    constructor(
        private platform: Platform,
        public modalController: ModalController,
        public components: ComponentsService,
        public api: ApiService
    ) {
        // this.auth.logOut()

        this.initializeApp();
    }

    initializeApp() {
        
    }

    

}

// Call this function when your app starts
