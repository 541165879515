// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
    production: false,
    cloudinary: {
        api_key: '321842221971572',
        cloud_name: 'ddqvtwvdk',
        api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
        upload: {
            url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
        },
        delete: {
            url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
        }
    },
    firebase: {
        apiKey: "AIzaSyA1hD_BpJ6184vLyrk4O3vlxLhg-Tb4SdQ",
        authDomain: "alfred-19.firebaseapp.com",
        projectId: "alfred-19",
        storageBucket: "alfred-19.appspot.com",
        messagingSenderId: "735543386222",
        appId: "1:735543386222:web:9b18ff8cb4a2bb9e3c467f"
    },
    payment_api: 'https://payments.alfred-app.com/payments.php',
    tokenization_api: 'https://payments.alfred-app.com/tokenization.php',
    api: 'https://us-central1-alfred-19.cloudfunctions.net/api-api'
};

