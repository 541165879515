import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { OneSignal } from '@ionic-native/onesignal/ngx';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

import { BrMaskerModule } from 'br-mask';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        IonicModule.forRoot({
            backButtonText: '',
            mode: 'ios',
            backButtonIcon: 'arrow-back-outline',
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBBURLg6G2RMj66jj-iDTAhMVDQxRRel9w',
            libraries: ['places', 'drawing', 'geometry']
        }),
        BrMaskerModule
    ],
    providers: [
        Geolocation,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        Keyboard,
        // OneSignal,
        // { provide: LOCALE_ID, useValue: 'es' }
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
