import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ComponentsService {

    private loading;
    isToastPresent = false;


    constructor(
        public modalController: ModalController,
        private loadingCtrl: LoadingController,
        private toastCtrl: ToastController,
        private alertCtrl: AlertController,
        public popoverController: PopoverController
    ) { }



    showLoader(message = 'Cargando...') {
        return new Promise((resolve, reject) => {
            if (!this.loading) {
                this.loadingCtrl.create({
                    message: message,
                    cssClass: 'scale-down-center urra-loader',
                    translucent: false,
                    showBackdrop: true,
                    spinner: null,
                }).then(res => {
                    this.loading = res;
                    this.loading.present();
                    resolve(true);
                })
            }
        })
    }

    dismissLoader() {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }
    }

    async showToast(message, color: any = 'dark', position: any = 'bottom',) {
        if (!this.isToastPresent) {
            this.isToastPresent = true;
            const toast = await this.toastCtrl.create({
                cssClass: 'toast',
                message: message,
                position: position,
                duration: 3000,
                color: color,
                buttons: [
                    {
                        icon: 'close',
                        handler: () => {
                        }
                    }
                ]
            });
            await toast.present();
            await toast.onDidDismiss().then(() => (this.isToastPresent = false));
        }
    }

    preventTouch(ev: any) {
        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }

    numericOnly(event): boolean {
        let pattern = /^[0-9]*$/;
        let result = pattern.test(event.key);
        return result;
    }

    checkValidate(form, value) {
        if (form.controls[value].value != '') {
            return form.controls[value].status;
        }
    }

    showProfile(key, profile = null) {
        
        // this.modalController.create({
        //     component: ProfilesComponent,
        //     animated: true,
        //     canDismiss: true,
        //     backdropDismiss: true,
        //     componentProps: {
        //         key: key,
        //         profile: profile
        //     }
        // }).then(modal => {
        //     modal.present();
        // });
    }

    showAlert(title, message, buttons) {
		this.alertCtrl.create({
			header: title,
			message: message,
			buttons: buttons
		}).then(alert => alert.present());
	}

}
